/* Fix navigation */

.fix-nav {
	position: fixed;
	right: 0;
	top: 80%;
	height: 130px;
	width: 130px;
	margin-top: -70px;
	z-index: 60;
	background: #26ebae;
	border: #26ebae;
	border-width: 10px;
	border-style: solid;

	.fix-nav-wraper {
		padding: 5px;
		//overflow: hidden;

		a {
			text-decoration: none;
			font-family: Tabac Sans, sans-serif;
			font-size: 1.125rem;
			line-height: 1.37em;
			font-weight: 500;
			color: $white;
			text-align: center;
		}

		img {
			margin: 0 auto;
			margin-top: 10px;
			margin-bottom: 11px;
		}

		&:hover {
			background: $gold;
		}

		p {
			margin-bottom: 0px;
		}
	}
}
