/************************************************************************************
FOOTER
*************************************************************************************/

.footer {
	background: #000;
	position: relative;
	overflow: hidden;
	display: flex;

	.footer-text {
		@include rem(padding, 50px 0 50px 0);
	}

	.contact-list {
		@include rem(margin-bottom, 60px);

		ul {
			li {
				padding: 0;

				a {
					display: inline-flex;
					align-items: center;
					white-space: nowrap;
					color: $gold;
					text-decoration: none;

					&:hover {
						color: #fff;
					}

					.icon {
						@include rem(width, 24px);
						@include rem(height, 24px);
						@include rem(flex, 0 0 24px);
						@include rem(margin-right, 10px);
					}
				}
			}
		}
	}

	.foot-content {
		flex: 0 0 100%;
	}

	.footer-menu {
		padding: 50px 50px 50px 0;
		@include rem(font-size, 18px);
		line-height: 1.3em;
		text-transform: none;
		max-width: 800px;

		ul {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			li {
				ul {
					position: absolute;
					flex-direction: column;
					@include rem(margin-top, 20px);

					li {
						padding: 0;
					}
				}
			}
		}

		a {
			color: $gold;
			text-decoration: none;

			&:hover {
				color: #fff;
			}
		}
	}

	.footer-link-list {
		@include rem(margin-bottom, 60px);
		position: relative;
		z-index: 2;

		ul {
			li {
				padding: 0;

				a {
					display: inline-flex;
					align-items: center;
					white-space: nowrap;
					color: $gold;
					text-decoration: none;

					&:hover {
						color: #fff;
					}

					.icon {
						@include rem(width, 24px);
						@include rem(height, 24px);
						@include rem(flex, 0 0 24px);
						@include rem(margin-right, 10px);
					}
				}
			}
		}
	}

	.foot-map {
		display: flex;
		justify-content: flex-end;
		left: 0;
		top: 0;
		width: 100%;
		max-height: 300px;
		margin-top: 180px;
		margin-left: -100%;
		padding-left: 250px;

		.inner {
			width: 100%;
			max-width: 1366px;
			position: relative;
		}

		.map-wrapper {
			position: relative;
		}

		.marker {
			position: absolute;
			@include translate(-50%, -50%);
			@include rem(width, 52px);
			@include rem(height, 52px);
			background: $gold;
			display: flex;
			justify-content: center;
			align-items: center;

			&:after {
				content: "";
				position: absolute;
				left: 50%;
				margin-left: -12px;
				bottom: -9px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 10px 12px 0 12px;
				border-color: $gold transparent transparent transparent;
			}

			.icon {
				background: url("../img/symbol.svg") center center no-repeat;
				@include rem(width, 38px);
				@include rem(height, 38px);
				@include rem(flex, 0 0 38px);
				color: #fff;
				background-size: contain !important;
			}
		}

		//praha
		.marker-1 {
			left: 54%;
			top: 29%;
		}

		//brno
		.marker-2 {
			left: 58%;
			top: 33%;
		}

		//brusel
		.marker-3 {
			left: 37%;
			top: 31%;
		}
		//ostrava
		.marker-4 {
			left: 60.5%;
			top: 28%;
		}

		//krakov
		.marker-5 {
			left: 64%;
			top: 20%;
		}
	}
}

.logo-foot {
	@include rem(margin-bottom, 60px);

	.suphead {
		@include rem(font-size, 18px);
		line-height: 1.3em;
		color: $gold;
		text-transform: none;
	}
}

.social-nav {
	ul {
		@include rem(margin, -2px -12px);
		display: flex;

		li {
			@include rem(padding, 0 12px);
			@include rem(margin, 2px 0);

			a {
				@include rem(width, 35px);
				@include rem(height, 35px);
				@include rem(flex, 0 0 35px);
				color: #fff;
				@include transition(all, 0.3);

				&:hover {
					color: $gold;
				}

				.icon {
					@include rem(width, 35px);
					@include rem(height, 35px);
				}
			}
		}
	}
}
