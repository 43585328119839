/************************************************************************************
RESET
*************************************************************************************/

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
menu,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	font-size: 100%;
	vertical-align: baseline;
	background: transparent;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}

:focus {
	outline: 0;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

article,
aside,
figure,
footer,
header,
hgroup,
nav,
section,
img {
	display: block;
}

input[type="radio"] {
	vertical-align: text-bottom;
}
input[type="checkbox"] {
	vertical-align: bottom;
	*vertical-align: baseline;
}

label,
input[type="button"],
input[type="submit"],
button {
	cursor: pointer;
	overflow: visible;
}

em,
i {
	font-weight: inherit;
}

/************************************************************************************
FONT FACE
*************************************************************************************/

@font-face {
	font-family: "Tabac Sans";
	src: url("../font/subset-TabacSansSemiBold.woff2") format("woff2"),
		url("../font/subset-TabacSansSemiBold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Tabac Sans";
	src: url("../font/subset-TabacSansMedium.woff2") format("woff2"),
		url("../font/subset-TabacSansMedium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Tabac Sans";
	src: url("../font/subset-TabacSans.woff2") format("woff2"),
		url("../font/subset-TabacSans.woff") format("woff");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

/************************************************************************************
GENERAL
*************************************************************************************/

body {
	font-family: Tabac Sans, sans-serif;
	@include rem(font-size, 18px);
	line-height: 1.6em;
	font-weight: 500;
	color: #000;
	background: #fff;
	margin: 0;
	padding: 0;
	min-height: 100%;

	&.modal-opened {
		overflow: hidden;
	}
}

/* Box sizing */

*,
*:after,
*:before {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

/* Anchors */

a {
	color: $black;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}

.teaser-perex {
	a {
		color: $gold;
	}
}

.teaser-alt {
	a {
		color: $gold;
	}
}

/* Heading */

h1,
.h1 {
	font-family: Tabac Sans, sans-serif;
	@include rem(font-size, 48px);
	line-height: 1.3em;
	font-weight: 400;
	color: #000;
	@include rem(margin, 0 0 30px 0);

	&.big {
		@include rem(font-size, 60px);
		line-height: 1.1em;
	}

	&.small {
		@include rem(font-size, 36px);
		line-height: 1.3em;
	}
}

h2,
.h2 {
	font-family: Tabac Sans, sans-serif;
	@include rem(font-size, 48px);
	line-height: 1.1em;
	font-weight: 600;
	color: $gold;
	@include rem(margin, 0 0 10px 0);

	&.small {
		@include rem(font-size, 32px);
	}

	&.white {
		color: #fff;
	}
}

h3,
.h3 {
	font-family: Tabac Sans, sans-serif;
	@include rem(font-size, 32px);
	line-height: 1.2em;
	font-weight: 600;
	color: $black;
	@include rem(margin, 0 0 10px 0);

	&.small {
		@include rem(font-size, 28px);
	}
}

h4,
.h4 {
	font-family: Tabac Sans, sans-serif;
	@include rem(font-size, 25px);
	line-height: 1.3em;
	font-weight: 400;
	color: #000;
	@include rem(margin, 0 0 10px 0);
}

h5,
.h5 {
	font-family: Tabac Sans, sans-serif;
	@include rem(font-size, 20px);
	line-height: 1.3em;
	font-weight: 400;
	color: #000;
	@include rem(margin, 0 0 10px 0);
}

h6,
.h6 {
	font-family: Tabac Sans, sans-serif;
	@include rem(font-size, 20px);
	line-height: 1.3em;
	font-weight: 400;
	color: #000;
	@include rem(margin, 0 0 10px 0);
}

.suphead {
	@include rem(font-size, 17px);
	color: #8995a6;
	text-transform: uppercase;
	letter-spacing: 0.02em;
}

/* Paragraphs */

p {
	@include rem(margin, 0 0 20px 0);
}

address,
cite {
	font-style: normal;
}

/* Lists */

ul {
	list-style: none;
	@include rem(margin, 0 0 20px 0);
	padding: 0;

	li {
		@include rem(padding, 0 0 0 20px);
		@include rem(margin, 0 0 5px 0);
	}

	&.unstyled li {
		padding: 0;
		background: none;
	}
}

ol {
	list-style: none;
	@include rem(margin, 0 0 20px 0);
	padding: 0;
	counter-reset: counter;
	margin-left: 0;

	li {
		@include rem(margin, 0 0 10px 0);
		padding: 0 0 0 20px;
		counter-increment: counter;
		position: relative;

		&:before {
			color: #2285c8;
			content: counter(counter) ". ";
			position: absolute;
			left: 0;
			top: 0;
		}
	}
}

dl {
	@include rem(margin, 0 0 20px 0);
}

dt {
	font-weight: bold;
}

/* Various */

sup,
sub {
	height: 0;
	line-height: 1;
	vertical-align: baseline;
	_vertical-align: bottom;
	position: relative;
}

sup {
	bottom: 1ex;
}

sub {
	top: 0.5ex;
}

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}

img {
	max-width: 100%;
	height: auto;
}

.bold,
b,
strong {
	font-weight: 600;
}

.italic,
i,
em {
	font-style: italic;
}

.nowrap,
.nobr {
	white-space: nowrap !important;
}

/************************************************************************************
CONTENT
*************************************************************************************/

.content {
	padding: 0;
	overflow: hidden;
}

.container {
	max-width: 1640px;
}

/* Block */

.block {
	@include rem(padding, 80px 0);
	position: relative;

	&.narrow {
		@include rem(padding, 40px 0);
	}

	&.alt {
		background: $grey;
	}

	&.alt-2 {
		background: $gold;
	}

	&.alt-3 {
		background: $black;
	}

	&.alt-4 {
		background: #3c3c3c;
	}

	&.alpha {
		padding-top: 0;
	}

	&.omega {
		padding-bottom: 0;
	}
}

.anchor {
	position: absolute;
	left: 0;
	@include rem(top, 0);
}

.spacer {
	@include rem(height, 80px);

	&.huge {
		@include rem(height, 160px);
	}

	&.big {
		@include rem(height, 100px);
	}

	&.medium {
		@include rem(height, 60px);
	}

	&.small {
		@include rem(height, 40px);
	}
}

/* Various */

.section-head {
	@include rem(margin-bottom, 40px);
	text-align: center;

	h1,
	h2,
	h3 {
		margin-bottom: 0;
	}
}

.section-head-l {
	@include rem(margin-bottom, 40px);

	h1,
	h2,
	h3 {
		margin-bottom: 0;
	}
}

.intro {
	max-width: 580px;
	@include rem(margin, 0 auto 40px auto);
	text-align: center;

	&.wide {
		@include rem(max-width, 750px);
	}
}

.invert {
	color: #fff;

	h2,
	h3 {
		color: #fff;
	}
}

/* Pager */

.pager {
	@include rem(margin, 30px 0);
	text-align: center;
	position: relative;
	display: flex;
	justify-content: space-between;

	ul {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	li {
		padding: 0;
		background: none;

		a {
			display: flex;
			align-items: center;
			justify-content: space-between;
			@include rem(width, 36px);
			@include rem(height, 36px);
			background: #666;
			text-align: center;

			&.active {
				background: #000;
				color: #fff;
				text-decoration: none;
			}
		}
	}

	.prev {
		@include rem(width, 36px);
		@include rem(height, 36px);
		background: #000;
	}

	.next {
		@include rem(width, 36px);
		@include rem(height, 36px);
		background: #000;
	}

	.counter {
		display: none;
	}
}

/* Gmap */

// .gmap-wrap {
// 	position: relative;

// 	.gmap {
// 		height: 600px;
// 		background: #e3e3e3;
// 	}

// 	img {
// 		max-width: inherit !important;
// 	}
// }

.sh-content {
	display: none;
}

/* Gmap old sites*/

.gmap-wrap {
	position: relative;
}
.gmap {
	width: 100%;
	height: 400px;

	&-detail {
		margin: 2em 0 3em 0;
	}
}
.gmap img {
	max-width: inherit;
}

.street-view {
	margin: 3em 0;

	&:last-child {
		margin-bottom: 1em;
	}

	iframe {
		width: 100%;
	}
}
.popup-contact {
	background: $white;
	color: $black;
	width: 190px !important;
	height: 80px;
	padding: 0.75em;
}
.popup-contact ul {
	margin: 0;
}
.popup-contact ul li {
	padding: 0;
	background: none;
}

/*================================= Various Content =================================*/

.last {
	margin-bottom: 0 !important;
}

/* Buttons */

.btn {
	@include rem(font-size, 20px);
	font-family: Tabac Sans, sans-serif;
	line-height: 1em;
	font-weight: 500;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	background: $gold;
	color: #fff;
	@include rem(padding, 0 16px);
	@include rem(height, 49px);
	text-decoration: none;
	position: relative;
	border: 2px solid $gold;
	@include transition(all, 0.5);

	&:hover {
		background: $black;
		border-color: $black;
		color: $gold;
	}

	&.alt {
		background: #fff;
		border-color: #fff;
		color: $gold;

		&:hover {
			background: $black;
			border-color: $black;
			color: $gold;
		}
	}

	&.alt-2 {
		background: $black;
		border-color: $black;
		color: $gold;

		&:hover {
			background: $white;
			border-color: $white;
			color: $black;
		}
	}

	&.alt-3 {
		background: $green;
		border-color: $green;
		color: $white;

		&:hover {
			background: $black;
			border-color: $black;
			color: $white;
		}
	}

	&.oneline {
		background: none;
		border: none;
		border-top: 2px solid $gold;
		color: $gold;

		&:hover {
			border-color: $black;
			color: $black;
		}
	}
}

.new-button {
	@include rem(font-size, 20px);
	font-family: Tabac Sans, sans-serif;
	line-height: 1em;
	font-weight: 500;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	background: $gold;
	color: #fff;
	@include rem(padding, 0 16px);
	@include rem(height, 49px);
	text-decoration: none;
	position: relative;
	border: 2px solid $gold;
	@include transition(all, 0.5);

	&:hover {
		background: $black;
		border-color: $black;
		color: $gold;
	}

	&.alt {
		background: #fff;
		border-color: #fff;
		color: $gold;

		&:hover {
			background: $black;
			border-color: $black;
			color: $gold;
		}
	}

	&.alt-2 {
		background: $black;
		border-color: $black;
		color: $gold;
	}

	&.oneline {
		background: none;
		border: none;
		border-top: 2px solid $gold;
		color: $gold;

		&:hover {
			border-color: $black;
			color: $black;
		}
	}
}

/* SVG icons */

.icon {
	width: 20px;
	height: 20px;
	display: inline-block;
	vertical-align: top;
	position: relative;
	fill: currentColor;
}

/* Cookie Notice */

.cookie-notice {
	font-size: 14px;
	position: fixed;
	z-index: 10000;
	bottom: 0;
	width: 100%;
	padding: 10px 15px;
	background: rgba(0, 0, 0, 0.9);
	color: #ccc;
	text-align: center;
}

.cookie-button {
	display: inline-block;
	padding: 2px 10px;
	margin-left: 25px;
	background: #02ad65;
	color: #fff;
	text-align: center;

	&:hover {
		background: #90ca16;
		color: #fff;
	}
}

.cookie-test {
	position: fixed;
	z-index: 10000;
	bottom: 0;
	width: 150px;
	padding: 25px 15px;
	background: rgba(50, 50, 50, 0.95);
	color: #ccc;
	text-align: center;
}

/* FY Apple! */

textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
.btn {
	-webkit-appearance: none;
}
/************************************************************************************
OLD SITES
*************************************************************************************/

.about-logo-wrapper {
	max-width: 360px;
	margin: 0 auto;
	height: 100%;
}
.about-logo {
	display: flex;
	height: 50%;

	a {
		padding: 5px 10px 5px 10px;
	}

	&.about-logo-second-box {
		a {
			padding: 5px 10px 5px 0;


			.certified {
				margin-left: 15px;
			}
		}
	}



	img {
		height: 180px;
	}

	.img-2020 {
		height: 183px;
		margin-top: 1px;
	}

	.img-2022 {
		height: 181px;
		margin-top: 1px;
	}
}

.persons-list {
	font-family: "Open Sans", Arial, "Helvetica Neue", Helvetica, sans-serif;
	font-weight: 700;
	font-size: 26px;
	line-height: 1.3;
	margin: 10px 0px 0px 0px;

	.person-box {
		margin-bottom: 50px;
		display: flex;
		justify-content: flex-start;
	}

	a {
		text-decoration: none;
	}

	a:hover .name {
		color: $gold;
	}

	img {
		margin-bottom: 20px;
	}

	.person .name {
		margin-bottom: 0.2em;
	}

	.job {
		margin-bottom: 0px;
		color: rgb(145, 145, 145);
		font-size: 17px;
		font-weight: 300;
		line-height: 1.3;
	}
}

.side-panel {
	margin-top: 40px;
}

.persons-list-side {
	img {
		position: absolute;
		left: 0px;
		top: 0px;
		max-width: 80px;
	}

	.person {
		display: block;
		position: relative;
		padding-left: 100px;
		min-height: 100px;
		text-decoration: none;
		@include rem(font-size, 17px);

		.name {
			color: $gold;
			display: block;
		}

		&:hover .name {
			text-decoration: underline;
		}

		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}
}

.article-list-side {
	a {
		text-decoration: none;
	}

	.title:hover h4 {
		text-decoration: underline;
	}

	.name:hover {
		text-decoration: underline;
	}

	h4 {
		color: $gold;
	}

	.info {
		@include rem(font-size, 17px);

		.name {
			margin-left: 10px;
		}
	}
}

.side-panel {
	.downloads {
		padding-left: 50px;
		min-height: 50px;
		position: relative;
		display: block;
		margin-bottom: 25px;

		a {
			text-decoration: none;
			padding-top: 5px;

			&:hover {
				text-decoration: underline;
			}
		}

		img {
			position: absolute;
			left: 0;
			top: 8px;
			width: 40px;
		}

		.title {
			color: #ad9652;
			display: block;
			font-size: 15px;
			font-weight: 700;
		}
	}
}

.article-list {
	.article {
		text-decoration: none;

		.title {
			margin-bottom: 0.4em;
			color: #ad9652;
			display: block;
			font-size: 22px;
			line-height: 1.2;
			font-weight: 700;
		}

		&:hover .title {
			text-decoration: underline;
		}
	}
}

.bg-mapa {
	pointer-events: none;
}

/************************************************************************************
ENTRY
*************************************************************************************/

.entry {
	@include rem(margin, 0 0 40px 0);

	h2,
	h3 {
		@include rem(margin-top, 30px);

		&::first-child {
			margin-top: 0;
		}
	}

	ul {
		li {
			position: relative;
			background: url("../img/ico/li.png") 0 6px no-repeat;

			&::marker {
				color: $grey;
			}

			&:before {
				content: "";
				display: block;
				width: 8px;
				height: 8px;
				background: #c2b07c;
				position: absolute;
				left: 0;
				top: 0.6em;
			}
		}
	}

	ol {
		li:before {
			color: $gold;
		}
	}

	a {
		text-decoration: none;
		color: $gold;

		&:hover {
			text-decoration: underline;
		}
	}

	& .new-button {
		color: $white;

		&:hover {
			text-decoration: none;
			color: $gold;
		}
	}
}

.post-block {
	ul {
		li {
			position: relative;

			&::marker {
				color: $grey;
			}

			&:before {
				content: "";
				display: block;
				width: 8px;
				height: 8px;
				background: #c2b07c;
				position: absolute;
				left: 0;
				top: 0.6em;
			}
		}
	}
}

.job-offer-detail {
	.entry {
		h2,
		h3 {
			@include rem(margin, 30px 0px 30px 0px);
		}

		ul {
			li {
				&:before {
					content: "";
					display: block;
					width: 8px;
					height: 8px;
					background: #c2b07c;
					position: absolute;
					left: 0;
					top: 0.6em;
				}
			}
		}
	}
}

/************************************************************************************
TABLES
*************************************************************************************/

.entry {
	table {
		width: 100%;
		@include rem(margin, 0 0 30px 0);

		th {
			font-weight: normal;
			border-bottom: 1px solid #c9cacb;
			@include rem(padding, 10px 20px);
			background: #e3e3e3;
			color: #000;
			text-align: left;
		}

		td {
			border-bottom: 1px solid #c9cacb;
			@include rem(padding, 10px 20px);
		}
	}
}

/************************************************************************************
FORMS
*************************************************************************************/

form.std {
	label {
		@include rem(font-size, 17px);
		line-height: 1.3em;
		color: #9f9f9f;
		@include rem(margin, 0 0 10px 0);
		display: block;
	}

	input.text {
		@include rem(font-size, 20px);
		font-family: Tabac Sans, sans-serif;
		line-height: 1.3em;
		color: #000;
		border: 2px solid #e7e7e7;
		@include rem(padding, 0 15px);
		@include rem(height, 50px);
		width: 100%;

		&.error {
			background: #fcf4f4;
			border-color: #be1e19;
		}

		&.req {
			background-image: url("../img/req.svg");
			background-position: right center;
			background-repeat: no-repeat;
		}
	}

	textarea {
		@include rem(font-size, 17px);
		font-family: Tabac Sans, sans-serif;
		line-height: 1.3em;
		color: #000;
		resize: vertical;
		border: 2px solid #e7e7e7;
		@include rem(padding, 5px 15px);
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		height: 150px;
		min-height: 150px;
		max-height: 300px;

		&.req {
			background-image: url("../img/req.svg");
			background-position: right center;
			background-repeat: no-repeat;
		}
	}

	select {
		@include rem(font-size, 17px);
		line-height: 1.3em;
		color: #000;
		width: 100%;
		@include rem(padding, 2px 10px);
	}

	option {
		@include rem(font-size, 17px);
		line-height: 1.3em;
		@include rem(padding, 2px 10px);
	}

	input.text[required="required"] {
		//background-image: url('../img/req.png');
		//background-position: right center;
		//background-repeat: no-repeat;
	}

	.cell {
		@include rem(margin-bottom, 20px);
		position: relative;
		text-align: left;
	}

	.toggle {
		position: relative;
		@include rem(padding, 0 0 0 25px);

		label {
			display: inline-block;
			vertical-align: top;
			margin: 0;
		}

		input {
			margin: 0;
			position: absolute;
			left: 0;
			@include rem(top, 2px);
		}
	}

	.radio,
	.checker {
		position: absolute;
		left: 0;
		top: 0;
	}

	.checker {
		position: absolute;
		left: 0;
		top: 0;
		background: url("../img/checker.svg") 0 0 no-repeat;
		background-color: #fff;
		@include rem(background-position, 0 -23px);
		@include rem(background-size, 23px);
		@include transition(all, 0.5);
		border: 2px solid #fff;
		@include rem(width, 26px);
		@include rem(height, 26px);
		@include rem(margin, 0);
		border-radius: 50%;
		cursor: pointer;

		input {
			opacity: 0;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			margin: 0;
			cursor: pointer;
		}

		&.active {
			@include rem(background-position, 0 0);
			background-color: transparent;
		}

		&.has-error {
			border-color: #be1e19;
		}
	}
}

form.form-nl {
	max-width: 270px;

	input.text {
		background: none;
		border: none;
		border-bottom: 3px solid #fff;
		padding: 10px 0;
		color: #fff;

		&::placeholder {
			color: rgba(255, 255, 255, 0.8);
		}
	}

	.btn {
		padding-left: 10px;
		padding-right: 10px;
		width: 100%;

		&:hover {
			background: #fff;
			border-color: #fff;
			color: $black;
		}
	}

	&.alt {
		margin: 0 auto;
		max-width: 560px;

		.cell-set {
			@include rem(margin-bottom, -20px);
		}

		.btn {
			background: $gold;
			border-color: $gold;
			color: #3c3c3c;

			&:hover {
				background: #fff;
				border-color: #fff;
				color: $black;
			}
		}
	}
}

#form-ajax-content {
	position: relative;

	&.loading:after {
		content: "";
		position: absolute;
		z-index: 90;
		left: 50%;
		top: 50%;
		width: 70px;
		height: 70px;
		background: url("../img/spinner.svg") center center no-repeat;
		background-size: cover !important;
		@include translate(-50%, -50%);
	}

	& > .inner {
		opacity: 1;
		@include transition(all, 0.3);
	}

	&.loading > .inner {
		opacity: 0.4;
	}
}

/*================================= Contact form 7 =================================*/

/* Mesages & errors */

body {
	.wpcf7 {
		div.wpcf7-response-output {
			padding: 0;
			background: #ebf5bc;
			border: 1px solid #f1c7d2;
			color: #46b450;
			@include rem(padding, 15px 20px);
			@include rem(margin, 0 0 30px 0);
		}

		form.invalid,
		form.unaccepted {
			div.wpcf7-response-output {
				border: 1px solid #f1c7d2;
				background: #fae7ea;
				color: #e5547a;
			}
		}
	}

	span.wpcf7-not-valid-tip {
		font-size: 13px;
	}

	span.wpcf7-list-item {
		margin: 0;
	}

	.wpcf7-form-control-wrap {
		position: static;
	}
}

/************************************************************************************
TABS
*************************************************************************************/

.tabs {
	position: relative;

	.tab-nav {
		ul {
			margin: 0 0 0 -1px;
			padding: 0;
		}

		li {
			float: left;
			list-style-type: none;
			padding: 0;
			background: none;
			margin: 0 0 0 1px;

			a {
				display: block;
				text-decoration: none;
				outline: 0;
				padding: 15px 30px;
				background: #000;
				color: #fff;

				&:hover {
					background: #a0a0a0;
					color: #000;
				}

				&.selected {
					background: #e3e3e3;
					color: #000;
				}
			}
		}
	}
	.tab-content .tab {
		float: left;
		width: 100%;
		padding: 30px;
		background: #e3e3e3;
		display: none;

		&.selected {
			position: static !important;
			display: block;
		}
	}
}

/************************************************************************************
SIMPLE LIGHTBOX
*************************************************************************************/

html.slbActive {
	overflow: hidden;
}

.slbArrow {
	font-size: 15px;

	&.prev {
		background: #fff;
		width: 40px;
		height: 40px;
		margin-top: -20px;
		opacity: 1;
		@include transition(all, 0.5);

		&:before {
			border: none;
			border-right: 2px solid #000;
			border-bottom: 2px solid #000;
			@include rotate(135deg);
			width: 15px;
			height: 15px;
			margin-top: -7.5px;
			margin-left: -6px;
			@include transition(all, 0.5);
		}

		&:hover {
			opacity: 1;
			background: #000;

			&:before {
				border-right: 2px solid #fff;
				border-bottom: 2px solid #fff;
			}
		}
	}

	&.next {
		background: #fff;
		width: 40px;
		height: 40px;
		margin-top: -20px;
		opacity: 1;
		@include transition(all, 0.5);

		&:before {
			border: none;
			border-right: 2px solid #000;
			border-bottom: 2px solid #000;
			@include rotate(-45deg);
			width: 15px;
			height: 15px;
			margin-top: -7.5px;
			@include transition(all, 0.5);
		}

		&:hover {
			opacity: 1;
			background: #000;

			&:before {
				border-right: 2px solid #fff;
				border-bottom: 2px solid #fff;
			}
		}
	}
}

.slbCloseBtn {
	font-size: 30px;
	line-height: 40px;
	font-weight: 400;
	font-family: peachy-keen-jf, sans-serif;
	color: #fff;
	width: 40px;
	height: 40px;
	background: #fff;
	color: #000;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	right: 0;
	top: 0;
	position: fixed;
	font-size: 0;
	@include transition(all, 0.5);

	&:after {
		content: "";
		width: 24px;
		height: 2px;
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -12px;
		margin-top: -1px;
		display: block;
		background: #000;
		@include rotate(45deg);
		@include transition(all, 0.5);
	}

	&:before {
		content: "";
		width: 2px;
		height: 24px;
		position: absolute;
		left: 50%;
		top: 50%;
		margin-top: -12px;
		margin-left: -1px;
		display: block;
		background: #000;
		@include rotate(45deg);
		@include transition(all, 0.5);
	}

	&:hover {
		opacity: 1;
		background: #000;
		color: #fff;

		&:after {
			background: #fff;
			@include rotate(-45deg);
		}

		&:before {
			background: #fff;
			@include rotate(-45deg);
		}
	}
}

.slbImage {
	@include rem(padding, 15px 0);
}

.slbImageWrap {
	&:after {
		content: none;
	}
}

.slbCaption {
	@include rem(font-size, 16px);
	position: static;
	overflow: visible;
	white-space: normal;
	padding-top: 0;
}

/************************************************************************************
MODAL
*************************************************************************************/

.modal {
	display: none;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	background: rgba(0, 0, 0, 0.5);
	overflow: auto;
	align-items: center;
	justify-content: center;

	&.active {
		display: flex;
	}

	.modal-content {
		@include rem(padding, 50px);

		&.alt {
			background: #f9fbfc;
		}
	}

	.modal-box {
		@include rem(font-size, 18px);
		@include rem(max-width, 400px);
		background-color: #fff;
		line-height: 1.5em;
		margin: auto;
		position: relative;

		.modal-close {
			position: absolute;
			right: 0;
			top: 0;
			text-decoration: none;
			@include rem(width, 40px);
			@include rem(height, 40px);
			display: flex;
			justify-content: center;
			align-items: center;
			background: #000;
			color: #fff;

			&:after {
				content: "×";
				@include rem(font-size, 30px);
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #fff;
			}

			.icon {
				@include rem(width, 18px);
				@include rem(height, 18px);
				transform: rotate(0deg);
				@include transition(all, 0.3);
			}

			&:hover {
				background: #fff;

				&:after {
					color: #000;
				}

				.icon {
					transform: rotate(90deg);
				}
			}
		}

		&.modal-order {
			width: 100%;
			@include rem(max-width, 580px);
			background: #e5e5e5;
		}
	}

	.modal-box-wrap {
		width: 100%;
		//max-width: 500px;
		@include rem(padding, 50px 15px 15px);
		margin: auto;
		overflow: hidden;
		position: relative;
		animation-name: animateTop;
		animation-duration: 0.4s;
	}
}

@keyframes animateTop {
	from {
		top: -300px;
		opacity: 0;
	}

	to {
		top: 0;
		opacity: 1;
	}
}

/************************************************************************************
GLIDE
*************************************************************************************/

.glide__slides {
	margin: 0;
}

.glide__slide {
	padding: 0 !important;

	&:before {
		content: none !important;
	}
}

.glide-nav {
	@include rem(margin-top, 40px);
	position: relative;

	.glide__arrow--left {
		position: absolute;
		left: 0;
		top: 0;
		width: 43px;
		height: 37px;
		cursor: pointer;
	}

	.glide__arrow--right {
		position: absolute;
		right: 0;
		top: 0;
		width: 43px;
		height: 37px;
		cursor: pointer;
	}
}

.glide__bullets {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;

	.glide__bullet {
		background: #a9e5da;
		width: 9px;
		height: 9px;
		border-radius: 50%;
		padding: 0;
		margin: 0 7px;
		border: none;
		cursor: pointer;
		@include transition(all, 0.5);

		&.glide__bullet--active,
		&:hover {
			background: #ea4650;
		}
	}
}

/* Case studies */

.mobile-version {
	display: none;
}

.case-studies {
	.cases-slider {
		ul {
			li {
				a {
					text-decoration: none;
				}
			}
		}
	}

	.slider-nav {
		ul {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -6px;

			li {
				padding: 0 6px;

				a {
					@include rem(font-size, 20px);
					font-weight: 500;
					@include rem(width, 50px);
					@include rem(height, 50px);
					display: flex;
					justify-content: center;
					align-items: center;
					text-indent: 0;
					border-radius: 0;
					color: $gold;
					border: none;
					background: none;
					text-decoration: none;
					position: relative;

					.txt {
						position: relative;
						z-index: 10;
					}

					&:after {
						content: "";
						width: 100%;
						height: 2px;
						position: absolute;
						left: 0;
						top: 0;
						background: $gold;
						@include transition(all, 0.3);
					}

					&:hover {
						&:after {
							height: 100%;
							background: $black;
						}
					}
				}

				&.glide__bullet--active {
					a {
						background: $black;
						border-color: $black;

						&:after {
							content: none;
						}
					}
				}
			}
		}
	}
}

/************************************************************************************
	CONTACT FORM
*************************************************************************************/

.contact-form {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: #c8b98b;
	text-align: left;
	padding: 1em 0;
	color: white;
	z-index: 12000;
	border-bottom: 1px solid #333;
	padding: 50px 70px;

	.row-main {
		@include rem(padding, 0px 42px);
	}

	.contact-form-fields-wrapper {
		display: flex;
		justify-content: space-between;
	}

	.btn {
		&:hover {
			background: $white;
			border-color: $white;
			color: $gold;
		}
	}

	.contact-form-title {
		color: $white;
		font-size: 30px;
		line-height: 1.3;
	}
	&-text {
		font-size: 18px;
	}

	&-close {
		position: absolute;
		right: 15px;
		top: 10px;
		width: 40px;
		height: 40px;
		display: block;

		&:before,
		&:after {
			content: "";
			display: block;
			width: 4px;
			height: 30px;
			background: white;
			position: absolute;
			left: 17px;
			top: 5px;
			transition: background 0.15 ease-in;
		}

		&:before {
			transform: rotate(45deg);
		}

		&:after {
			transform: rotate(-45deg);
		}

		&:hover,
		&:active {
			&:before,
			&:after {
				background: $black;
			}
		}
	}

	p.h3 {
		margin-bottom: 0.5em;
	}

	.contact-form-heading {
		font-weight: bold;
		margin: 20px 0px 0px 0px;
	}

	.contact-form-text {
		color: $white;
		@include rem(font-size, 15px);
		line-height: 20px;
	}

	.contact-form-heading {
		line-height: 20px;
		margin-bottom: 5px;
	}

	.input-cells {
		p {
			margin-bottom: 5px;
		}

		.confirmation {
			display: flex;
			justify-content: flex-start;
		}

		.personal-details-information {
			padding-left: 25px;
		}

		.cell {
			text-align: end;
		}

		.contact-form-text {
			color: $black;

			.cell-input {
				padding-right: 5px;
			}
		}

		input[type="checkbox"] {
			vertical-align: top;
			*vertical-align: baseline;
			margin-top: 5px;
		}
	}
}

/* Forms */

.form-std {
	color: $black;

	input.text {
		vertical-align: middle;
		font-family: "Open Sans", Arial, sans-serif;
		color: $textColor;
		border: 1px solid $gray;
		padding: 8px 15px 6px 15px;
		width: 100%;
		padding: 13px;
		font-size: 15px;
		line-height: 18px;
		box-sizing: border-box;
	}

	textarea {
		font-family: "Open Sans", Arial, sans-serif;
		color: $textColor;
		resize: vertical;
		border: 1px solid $gray;
		padding: 8px 15px 6px 15px;
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		// height: 150px;
		// min-height: 230px;
		// max-height: 300px;
		box-sizing: border-box;
	}

	.cell {
		margin-bottom: 1em;
		position: relative;
	}
}

.form-cta .error-placement-wrapper {
	display: inline-block;
}

.form-newsletter {
	.input {
		margin-bottom: 0.8em;
	}

	input.text {
		margin-bottom: 0.15em;
		border: 1px solid darken($gray, 10%);
		box-shadow: inset 0 2px 0 #eeeeef;
		border-radius: 5px;
	}

	.checkers {
		margin-bottom: 1.5em;
	}

	&-close {
		position: absolute;
		right: 20px;
		top: 30px;
		width: 40px;
		height: 40px;
		display: block;
		z-index: 300;

		&:before,
		&:after {
			content: "";
			display: block;
			width: 4px;
			height: 30px;
			background: $darkColor;
			position: absolute;
			left: 17px;
			top: 5px;
			transition: background 0.15 ease-in;
		}

		&:before {
			transform: rotate(45deg);
		}

		&:after {
			transform: rotate(-45deg);
		}

		&:hover,
		&:active {
			&:before,
			&:after {
				background: $black;
			}
		}
	}
}

.form-cta input.text {
	width: 240px;
	box-shadow: inset 0 2px 0 #eeeeef;
	border-radius: 5px;
}

.form-cta button {
	margin-left: 10px;
}

.modal-newsletter {
	width: 600px;
	max-width: 100vw !important;
	text-align: left;
	padding: 20px;

	p {
		font-weight: 400;
	}

	.toggle {
		display: flex;
		align-items: center;
		label {
			font-weight: 400;
			order: 2;
			padding-left: 15px;
		}

		input {
			order: 1;
		}
	}

	a {
		color: $gold;
	}
}

.person-detail-img {
	background: url(../img/bg/bg_team-detail.png) repeat-y;
	padding-top: 60px;
	position: relative;
	height: 100%;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	-webkit-box-pack: end;
	justify-content: flex-end;
}

.person-detail {
	padding: 50px 0px;

	.job {
		color: #919191;
		font-size: 17px;
		font-weight: 300;
	}

	.specs {
		margin-left: 0;
		margin: 1em 0 4em 0;
		position: relative;

		&:before,
		&:after {
			content: "";
			display: block;
			width: 100%;
			height: 1px;
			position: absolute;
			left: 0;
			background: white url(../img/bg/dot_black3x1.gif) repeat-x left bottom;
		}

		&:before {
			top: -0.5em;
		}

		&:after {
			bottom: -0.5em;
		}

		li {
			display: inline-block;
			position: relative;
			padding-left: 0;
			margin-right: 1em;
			margin-bottom: 0;

			a {
				color: $gold;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}

			&:before {
				display: none;
			}

			&:not(:last-child) {
				margin-right: 1em;

				&:after {
					content: "";
					display: block;
					width: 3px;
					height: 3px;
					background: $gold;
					border-radius: 50%;
					position: absolute;
					right: -0.75em;
					top: 0.75em;
				}
			}
		}
	}

	.tabs {
		.tab-nav {
			a {
				background: $white;

				&.selected {
					background: $white;
				}

				&:hover {
					background: $white;
					color: $black;
				}
			}
		}

		.tab-content {
			.tab {
				background: $white;

				&.selected {
					padding: 30px 0px;
				}

				p {
					padding-left: 20px;
				}

				.info {
					font-size: 16px;

					ul {
						margin-top: 15px;
					}
					li {
						margin-bottom: 0;
						font-size: 15px;
						line-height: 18px;

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}

/************************************************************************************
CLEARFIX
*************************************************************************************/

/* http://www.yuiblog.com/blog/2010/09/27/clearfix-reloaded-overflowhidden-demystified */

.clearfix:before,
.clearfix:after {
	content: "\0020";
	display: block;
	overflow: hidden;
	visibility: hidden;
	width: 0;
	height: 0;
}

.clearfix:after {
	clear: both;
}

.clearfix {
	zoom: 1;
}
