/************************************************************************************
LAYOUT
*************************************************************************************/

.services {
	.item {
		@include rem(font-size, 18px);
		line-height: 1.5em;
		@include rem(margin-bottom, 16px);
	}

	.item-inner {
		background: $gold;
		color: $black;
		text-decoration: none;
		display: flex;
		position: relative;
		overflow: hidden;
		@include transition(all, 0.5);
		height: 100%;

		.text {
			flex: 0 0 80%;
			max-width: 80%;
			@include rem(padding, 35px 0 35px 35px);
			position: relative;
			z-index: 11;
		}

		.img {
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;

			.icon {
				width: 150%;
				flex: 0 0 150%;
				height: 100%;
				margin-right: -50%;
				margin-bottom: -20%;
				color: #b6aa80;
				z-index: 10;
				@include transition(all, 0.5);
			}
		}

		.btn-set {
			margin: -7px 0;
		}

		.btn {
			background: #3c3c3c;
			border-color: #3c3c3c;
			color: #fff;
			margin: 7px 0;
			white-space: nowrap;

			&:hover {
				background: #fff;
				border-color: #fff;
				color: $gold;
			}
		}

		h3 {
			color: $black;
			@include transition(all, 0.5);
		}

		p:last-child {
			margin-bottom: 0;
		}

		&:after {
			content: "";
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			background: $black;
			left: 100%;
			@include transition(all, 0.5);
		}

		&.alt-color {
			background: #2D6B5B;
			h3 {
				color: $gold;
			}

			p {
				color: $white;
			}

			.img {
				.icon {
					color: #26EBAE;
				}
			}
		}
	}

	a.item-inner {
		&:hover {
			color: #fff;

			h3 {
				color: $gold;
			}

			.icon {
				color: #2d6b5b;
			}

			&:after {
				left: 0;
			}
		}

		&.alt-color {
			&:hover {
				.icon {
					color: #fff;
				}
			}
		}
	}

	.foot {
		@include rem(padding-top, 16px);
	}
}

.eshop {
	.item {
		@include rem(font-size, 18px);
		line-height: 1.5em;
		@include rem(margin-bottom, 16px);
	}

	.item-inner {
		height: 100%;
		background: $gold;
		color: $black;
		text-decoration: none;
		display: flex;
		position: relative;
		overflow: hidden;

		.text {
			flex: 0 0 50%;
			max-width: 50%;
			@include rem(padding, 35px 10px 35px 35px);
			position: relative;
			z-index: 11;
		}

		.images {
			display: flex;
			height: 100%;

			.svg-img {
				display: flex;
				align-items: flex-end;
				justify-content: flex-end;

				.icon {
					width: 150%;
					flex: 0 0 150%;
					height: 100%;
					margin-right: -50%;
					margin-bottom: -20%;
					color: #b6aa80;
					z-index: 10;
				}
			}

			.img {
				position: relative;
				flex: 0 0 60%;
				max-width: 60%;
				height: 100%;
				z-index: 11;

				img {
					position: relative;
					width: 100%;
					height: 100%;
					object-fit: cover !important;
				}
			}
		}


		.btn-set {
			margin: -7px 0;
		}

		.btn {
			background: #3c3c3c;
			border-color: #3c3c3c;
			color: #fff;
			margin: 7px 0;
			white-space: nowrap;

			&:hover {
				background: #fff;
				border-color: #fff;
				color: $gold;
			}
		}

		h3 {
			color: $black;

		}

		p:last-child {
			margin-bottom: 0;
		}

		&.alt-color {
			background: #2D6B5B;
			h3 {
				color: $gold;
			}

			p {
				color: $white;
			}

			.img {
				.icon {
					color: #26EBAE;
				}
			}
		}
	}

	.foot {
		@include rem(padding-top, 16px);
	}
}

.references {
	.item-list {
		margin-left: -40px;
		margin-right: -40px;
	}

	.item {
		@include rem(margin-bottom, 60px);
		padding-left: 40px;
		padding-right: 40px;

		.img {
			display: flex;
			align-items: center;
			@include rem(height, 150px);
			margin-bottom: 20px;

			.frame {
				@include rem(max-width, 180px);
			}
		}

		.info {
			color: $gold;

			.name {
				@include rem(font-size, 20px);
				font-weight: 600;
				margin-bottom: 0;
			}

			.job {
				margin-bottom: 0;
			}
		}
	}
}

.section-bar {
	color: #fff;
	text-align: center;
	@include rem(padding, 40px 0);

	h2,
	h3 {
		color: #fff;
	}

	.section-head {
		@include rem(margin-bottom, 10px);
	}

	.intro {
		@include rem(font-size, 20px);
		font-weight: 600;
		max-width: 1000px;
		margin-bottom: 0;

		p:last-child {
			margin-bottom: 0;
		}
	}
}

.clients {
	.item-list {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin: -20px 0px 40px 0px;
	}

	.item {
		//padding: 0 50px;
		margin: 20px 0;

		img {
			margin: 0 auto;
		}
	}

	.btn {
		&:hover {
			background: #fff;
			border-color: #fff;
			color: $gold;
		}
	}
}

.posts {
	.item-post {
		@include rem(margin-bottom, 16px);
		display: flex;
		flex-direction: column;

		.text {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			position: relative;
			top: 0;
		}

		.img {
			position: relative;

			img {
				width: 100%;
				@include scale(1);
				@include transition(all, 0.3);
			}

			&.video {
				.play {
					@include rem(width, 84px);
					@include rem(height, 84px);
					display: inline-flex;
					justify-content: center;
					align-items: center;
					position: absolute;
					opacity: 1;
					z-index: 20;
					left: 50%;
					top: 50%;
					@include translate(-50%, -50%);
					@include transition(all, 0.3);
					background: $gold;

					&:after {
						content: "";
						width: 0;
						height: 0;
						border-style: solid;
						@include rem(border-width, 22.5px 0 22.5px 36px);
						border-color: transparent transparent transparent $black;
					}
				}

				&:after {
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background: rgba(0, 0, 0, 0.2);
					@include transition(all, 0.3);
				}
			}
		}

		.post-info {
			@include rem(padding, 0 24px 24px 24px);
			background: $gold;
			color: $black;
			position: relative;
			z-index: 10;
			@include transition(all, 0.3);
			margin-top: -1px;
		}

		.item-inner {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			text-decoration: none;
			overflow: hidden;

			&:hover {
				.text {
					.post-info {
						color: #fff;
						background: $black;
					}

					h3 {
						top: 20px;
						color: $gold;
						background: $black;
					}
				}

				.img {
					img {
						@include scale(1.2);
					}

					&:after {
						background: rgba(0, 0, 0, 0);
						@include scale(1.3);
					}

					.play {
						opacity: 0;
					}
				}
			}
		}

		h3 {
			position: relative;
			top: 0;
			@include rem(padding, 24px);
			margin: 0;
			background: $gold;
			flex-grow: 1;
			@include transition(all, 0.3);
		}
	}

	.item-nl {
		@include rem(font-size, 16px);
		line-height: 1.5em;
		color: $black;
		display: flex;
		flex-direction: column;
		@include rem(margin-bottom, 16px);

		.img {
			display: flex;
			justify-content: flex-end;
			flex-grow: 1;
			@include rem(margin, -32px -32px 0 -32px);

			.icon {
				@include rem(width, 250px);
				@include rem(height, 250px);
				color: #b6aa80;
				position: relative;
				right: -20px;
				top: 0;
				@include rem(margin-bottom, -67px);
				@include rem(margin-top, -35px);
			}
		}

		.item-inner {
			overflow: hidden;
			position: relative;
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			background: $gold;
			@include rem(padding, 32px 32px 48px 32px);
		}

		.text {
			position: relative;
			z-index: 10;
		}

		h3 {
			font-weight: 700;
			padding: 0;
		}
	}

	.foot {
		@include rem(padding-top, 16px);
	}
}

.post-info {
	@include rem(font-size, 14px);
	display: flex;
	justify-content: space-between;
	top: 0;
	flex-wrap: wrap;

	ul {
		margin: 0;
		padding-right: 15px;
		display: flex;
		justify-content: space-between;

		&:last-child {
			padding-right: 0;
		}

		a {
			color: #fff;
			text-decoration: none;

			&:hover {
				color: $gold;
			}
		}
		li {
			line-height: 1.3em;
			padding: 0 5px;

			&.date {
				white-space: nowrap;
				padding-right: 10px;
			}
		}
	}
}

.post-list {
	@include rem(margin-bottom, 60px);

	.item-post {
		@include rem(margin-bottom, 16px);
		display: flex;
		flex-direction: column;

		.img {
			position: relative;
			order: 0;
			flex: 0 0 400px;
			max-width: 400px;

			img {
				width: 100%;
				height: 100%;
				position: relative;
				object-fit: cover !important;
				@include scale(1);
				@include transition(all, 0.3);
			}

			&.video {
				.play {
					@include rem(width, 84px);
					@include rem(height, 84px);
					display: inline-flex;
					justify-content: center;
					align-items: center;
					position: absolute;
					opacity: 1;
					z-index: 20;
					left: 50%;
					top: 50%;
					@include translate(-50%, -50%);
					@include transition(all, 0.3);
					background: $gold;

					&:after {
						content: "";
						width: 0;
						height: 0;
						border-style: solid;
						@include rem(border-width, 22.5px 0 22.5px 36px);
						border-color: transparent transparent transparent $black;
					}
				}

				&:after {
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background: rgba(0, 0, 0, 0.2);
					@include transition(all, 0.3);
				}
			}
		}

		.text {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			position: relative;
			top: 0;
			order: 0;
			background: #fff;
		}

		.desc {
			@include rem(padding, 0 24px 24px 24px);
		}

		.post-info {
			@include rem(padding, 0 24px 24px 24px);
			color: $black;
			position: relative;
			z-index: 10;
			@include transition(all, 0.3);
			margin-top: -1px;
		}

		.item-inner {
			flex-grow: 1;
			display: flex;
			text-decoration: none;
			overflow: hidden;

			&:hover {
				h3 {
					text-decoration: underline;
				}
			}
		}

		h3 {
			position: relative;
			top: 0;
			@include rem(padding, 24px);
			margin: 0;
			flex-grow: 1;
			color: $gold;
			@include transition(all, 0.3);
		}
	}

	.foot {
		@include rem(padding-top, 32px);
	}
}

.post-detail {
	@include rem(font-size, 20px);
	line-height: 30px;
	@include rem(margin-bottom, 60px);

	h2 {
		@include rem(font-size, 36px);
		@include rem(margin-top, 40px);
		@include rem(margin-bottom, 20px);
	}

	h3 {
		@include rem(font-size, 25px);
		@include rem(margin-top, 40px);
		@include rem(margin-bottom, 20px);
	}

	.post-excerpt {
		@include rem(font-size, 20px);
		line-height: 1.8em;
		font-weight: 600;
		@include rem(margin, 20px 0);

		&:first-child {
			margin-top: 0;
		}
	}

	.post-block {
		//@include rem(max-width, 820px);
		@include rem(margin, 20px 0);

		&:first-child {
			margin-top: 0;
		}
	}

	.entry {
		img {
			display: inline;
		}
	}

	.post-video {
		img {
			width: 100%;
		}

		.frame {
			position: relative;
			display: block;
		}

		.play {
			position: absolute;
			left: 50%;
			top: 50%;
			@include translate(-50%, -50%);
			@include rem(width, 84px);
			@include rem(height, 84px);
			background: $gold;

			&:after {
				content: "";
				position: absolute;
				left: 50%;
				top: 50%;
				@include translate(-50%, -50%);
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 23px 0 23px 36px;
				border-color: transparent transparent transparent $black;
			}
		}
	}
}

.box-nl {
	width: 100%;
	max-width: 390px;
	@include rem(font-size, 16px);
	line-height: 1.5em;
	color: $black;
	display: flex;
	flex-direction: column;
	@include rem(margin-bottom, 16px);

	.img {
		display: flex;
		justify-content: flex-end;
		flex-grow: 1;
		@include rem(margin, -32px -32px 0 -32px);

		.icon {
			@include rem(width, 250px);
			@include rem(height, 250px);
			color: #b6aa80;
			position: relative;
			right: -20px;
			top: 0;
			@include rem(margin-bottom, -67px);
			@include rem(margin-top, -35px);
		}
	}

	img {
		@include rem(margin-bottom, 32px);
	}

	.item-inner {
		overflow: hidden;
		position: relative;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		background: $gold;
		@include rem(padding, 32px);
	}

	.text {
		position: relative;
		z-index: 10;
	}

	.desc-big {
		@include rem(font-size, 18px);
		line-height: 1.5em;
	}

	.btn {
		&:hover {
			border-color: #fff;
			background: #fff;
			color: $gold;
		}
	}

	h3 {
		font-weight: 700;
		padding: 0;
	}
}

.box-cats {
	width: 100%;
	max-width: 390px;
	background: $black;
	@include rem(padding, 30px);
	@include rem(font-size, 18px);
	@include rem(margin-bottom, 16px);

	h3 {
		color: $gold;
		@include rem(margin-bottom, 20px);
	}

	ul {
		margin: 0;

		li {
			padding: 0;

			&.active {
				a {
					color: $gold;
				}
			}
		}
	}

	a {
		color: #fff;
		text-decoration: none;

		&:hover {
			color: $gold;
		}
	}
}

.cta {
	padding: 0 15px;
	@include rem(padding-top, 55px);
	@include rem(padding-bottom, 55px);

	.section-head {
		@include rem(margin-bottom, 20px);
	}

	.intro {
		font-size: 18px;
		margin-bottom: 0;
		max-width: 550px;

		.btn-set {
			@include rem(padding-top, 20px);
		}

		p:last-child {
			margin-bottom: 0;
		}
	}
}

.case-studies {
	.img {
		position: relative;

		img {
			width: 100%;
			@include scale(1);
			@include transition(all, 0.3);
		}

		&.video {
			.play {
				@include rem(width, 84px);
				@include rem(height, 84px);
				display: inline-flex;
				justify-content: center;
				align-items: center;
				position: absolute;
				opacity: 1;
				z-index: 20;
				left: 50%;
				top: 50%;
				@include translate(-50%, -50%);
				@include transition(all, 0.3);
				background: $gold;

				&:after {
					content: "";
					width: 0;
					height: 0;
					border-style: solid;
					@include rem(border-width, 22.5px 0 22.5px 36px);
					border-color: transparent transparent transparent $black;
				}
			}

			&:after {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background: rgba(0, 0, 0, 0.2);
				@include transition(all, 0.3);
			}
		}
	}

	.item {
		.text {
			@include rem(padding, 50px 40px);
			background: $gold;
			color: $black;
			flex: 0 0 50%;
			max-width: 50%;
			order: 0;
			display: flex;
			align-items: center;
			align-content: space-between;
			flex-wrap: wrap;

			h3 {
				color: $black;
			}

			.btn {
				&:hover {
					background: #fff;
					border-color: #fff;
					color: $black;
				}
			}
		}

		.img {
			flex: 0 0 50%;
			max-width: 50%;
			order: 1;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover !important;
			}
		}
	}

	.item-inner {
		display: flex;

		.text {
			order: 0;
		}
	}

	.foot {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		@include rem(padding-top, 20px);

		.slider-nav {
			margin: 5px 0;
		}

		.btn {
			margin: 5px 0;
		}
	}
}

.contact-city {
	a {
		text-decoration: none;
		color: $gold;

		&:hover {
			text-decoration: underline;
		}
	}
}

.cities {
	@include rem(margin-bottom, 50px);

	ul {
		display: flex;
		@include rem(margin, 0 -10px);

		li {
			@include rem(font-size, 16px);
			line-height: 1.3em;
			@include rem(padding, 0 10px);
			margin: 0;
			color: $gold;

			a {
				color: $gold;
			}
		}
	}
}

.footer {
	.cities {
		position: relative;
		z-index: 2;
	}
}

.newsletter {
	color: #fff;
	padding: 0 15px;

	.section-head {
		@include rem(margin-bottom, 20px);
	}

	h2 {
		color: $gold;
	}
}

.tiles {
	.item-list {
		margin: 0;
	}

	.item {
		padding: 0;
		display: flex;

		& > * {
			width: 100%;
		}
	}
}

.contact-details {
	.first-element {
		background: #f0f0f0;
	}

	.contact-details-element {
		@include rem(padding, 20px);
	}

	.head {
		margin-top: 0px;
	}
}

.law-services {
	@include rem(margin-bottom, 40px);
	@include rem(padding-top, 20px);

	&:last-child {
		margin-bottom: 0;
	}

	.section-head-l {
		h2 {
			@include rem(margin-bottom, 30px);
		}

		h3 {
			a {
				text-decoration: none;

				&:hover {
					color: $gold;
				}
			}
		}
	}

	.item:first-child {
		.item-inner {
			background: #f0f0f0;
		}
	}

	.item {
		@include rem(margin-bottom, 30px);

		.head {
			//min-height: 66px;
			//display: flex;
			align-items: flex-end;
			@include rem(margin-bottom, 20px);
			align-self: flex-start;

			h3 {
				color: $gold;
				margin: 0;
			}
		}

		.links {
			@include rem(margin, 20px 0);

			ul {
				display: flex;
				flex-wrap: wrap;
				@include rem(margin, -5px -10px);

				li {
					@include rem(padding, 0 10px);
					@include rem(margin, 5px 0);
				}
			}
		}

		.desc {
			@include rem(font-size, 16px);
			line-height: 1.8em;
			align-self: flex-start;
		}

		&.alt {
			.item-inner {
				background: #f0f0f0;
			}
		}
	}

	.item-inner {
		@include rem(padding, 30px);
		height: 100%;
		display: flex;
		flex-wrap: wrap;

		a {
			align-self: flex-end;
		}
	}
}
