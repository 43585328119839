/**
/* Old web
 */

$mainColor: #c2b07c;
$darkerMainColor: #937f45;
$darkColor: #21262e;
$textColor: #333;
$white: #fff;
$gray: #f3f3f3;
$darkGray: #919191;
$black: #000;
$green: #26ebad;

$baseFont: "Open Sans", Arial, "Helvetica Neue", Helvetica, sans-serif;
$headingFont: "Tabac Sans Thin", "Open Sans", Arial, "Helvetica Neue", Helvetica,
	sans-serif;
$fbFont: "Frank-Bold-Basic", "Open Sans", Arial, "Helvetica Neue", Helvetica,
	sans-serif;

$lg: 1399px;
$md: 1199px;
$sm: 991px;
$xs: 767px;
$xxs: 479px;
$xxxs: 370px;

/**
/* New web
 */

/**
 * Colors
 */
$black: #21262e;
$white: #fff;
$gold: #c8b98b;
$green: #26ebae;
$grey: #f0f0f0;

/**
/* Font weight variables
 */
$font-light: 100;
$font-normal: 300;
$font-medium: 500;
$font-semibold: 700;
$font-bold: 900;
