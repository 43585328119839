/************************************************************************************
MEDIA QUERIES
*************************************************************************************/

/*========================= Viewport width 1560px and lower =========================*/

@media screen and (max-width: 1560px) {
	.header {
		.h_0 {
			padding-left: 50px;
			padding-right: 50px;
		}
		.h_1 {
			padding-left: 50px;
			padding-right: 50px;
		}
	}

	.teaser-alt {
		padding-left: 50px;
		padding-right: 50px;
	}

	.block {
		padding-left: 50px;
		padding-right: 50px;
	}

	.footer-text {
		margin-left: 50px;
	}

	.teaser-hp {
		margin-bottom: 100px;

		.teaser-content {
			.text {
				@include rem(font-size, 18px);
				max-width: 600px;
				@include rem(padding, 40px);
				margin-bottom: -100px;
			}
		}
	}

	.glide__slide {
		height: auto;

		.item {
			height: 100%;

			.item-inner {
				height: 100%;

				.text {
					height: 100%;
				}

				.img {
					height: 100%;
				}
			}
		}
	}
	.clients {
		.item-list {
			// margin-left: -25px;
			// margin-right: -25px;
		}
	}

	h1.big {
		@include rem(font-size, 50px);
	}

	.contact-form {
		padding: 20px 50px;

		textarea {
			height: 150px;
		}
	}

	.main-nav {
		ul {
			li {
				padding-left: 0.6rem;
				padding-right: 0.6rem;

				a {
					padding-left: 8px;
					padding-right: 8px;
				}
			}
		}
	}
}

/*========================= Viewport width 1360px and lower =========================*/

@media screen and (max-width: 1360px) {
	.header {
		.logo-cities-wrap {
			max-width: 300px;
		}
	}

	.main-nav {
		ul {
			li {
				a {
					@include rem(font-size, 16px);
					padding-left: 10px;
					padding-right: 10px;
				}

				ul {
					li {
						a {
							@include rem(font-size, 16px);
							padding-left: 10px;
							padding-right: 10px;
							min-width: initial;
						}
					}
				}
			}
		}
	}

	.case-studies {
		.item {
			.text {
				@include rem(padding, 40px);
			}
		}
	}

	form.form-nl {
		&.alt {
			max-width: 350px;

			.cell-set {
				flex-wrap: wrap;

				.cell {
					flex: 0 0 100%;
					max-width: 100%;
				}
			}
		}
	}

	.contact-form {
		textarea {
			height: 130px;
		}
	}
}

/*========================= Viewport width 1199px and lower =========================*/

@media screen and (max-width: 1199px) {
	.header {
		.h_1 {
			.inner {
				height: 120px;
			}
		}
	}

	.main-nav {
		ul {
			li {
				a {
					@include rem(font-size, 16px);
					padding-left: 5px;
					padding-right: 5px;
				}
			}
		}
	}

	.teaser-hp {
		flex-wrap: wrap;

		.teaser-img {
			margin-right: 0;
		}

		.teaser-content {
			width: auto;
			margin: 0 -15px;
			flex-grow: 1;

			.text {
				width: 100%;
				max-width: 100%;
				padding-left: 50px;
			}
		}
	}

	.box-cats,
	.box-nl {
		max-width: 100%;
	}

	.footer {
		display: block;

		.footer-menu {
			padding-right: 0;
			ul {
				flex-direction: column;
				max-width: 220px;
				margin: 0 auto;
				//align-content: center;
				//justify-content: space-around;

				li {
					ul {
						position: relative;
						@include rem(margin, 10px 0 0 0);

						li {
							padding-left: 10px;
						}
					}
				}
			}
		}

		.foot-map {
			margin-left: 0;
			margin-top: 0;
			padding-left: 0;
			border-top: 1px solid rgba(255, 255, 255, 0.1);
		}
	}

	.contact-form {
		padding: 20px 0px;
	}

	.mobile-hidden {
		display: none;
	}

	.about-logo-wrapper {
		max-width: 181px;
	}

	.about-logo {
		flex-wrap: wrap;

		.certified {
			padding-left: 13px;
		}
	}
}

/*========================= Viewport width 991px and lower =========================*/

@media screen and (max-width: 991px) {
	html {
		font-size: 16px;
	}

	h1,
	.h1 {
		@include rem(font-size, 38px);
	}

	h2,
	.h2 {
		@include rem(font-size, 38px);
	}

	.header {
		.h_0 {
			padding-left: 30px;
			padding-right: 30px;
		}
		.h_1 {
			padding-left: 30px;
			padding-right: 30px;
		}
	}

	.teaser-alt {
		padding-left: 30px;
		padding-right: 30px;

		&.teaser-breadcrumbs-transparent {
			position: relative;
			background: $darkColor;
		}
	}

	.block {
		padding-left: 30px;
		padding-right: 30px;
	}

	.footer-text {
		margin-left: 30px;
	}

	.main-nav {
		display: none;
	}

	.nav-btn {
		display: block;
	}

	.mobile-nav-wrap {
		display: block;
	}

	.footer {
		.foot-map {
			display: none;
		}
	}

	.contact-form {
		padding: 20px 0px;
		.input-cells {
			.confirmation {
				justify-content: flex-start;
			}
		}
	}

	.flex-break {
		flex-basis: 100%;
	}
}

/*========================= Viewport width 767px and lower =========================*/

@media screen and (max-width: 767px) {
	html {
		font-size: 15px;
	}

	.header {
		.contact-list-mini {
			display: none;
		}
	}

	.footer {
		.footer-menu {
			padding-top: 0;
			margin-left: 13px;

			ul {
				max-width: 100%;
			}
		}
	}

	.glide__slide {
		height: auto;

		.item {
			height: 100%;

			.item-inner {
				height: 100%;

				.text {
					height: 60%;
				}

				.img {
					height: 40%;
				}
			}
		}
	}

	.case-studies {
		.item {
			.img {
				order: 0;
				flex: 0 0 100%;
				max-width: 100%;
			}

			.text {
				order: 1;
				flex: 0 0 100%;
				max-width: 100%;
			}
		}

		.item-inner {
			flex-wrap: wrap;
		}
	}

	.post-list {
		.item-post {
			.item-inner {
				//flex-wrap: wrap;
			}

			.img {
				//order: 0;
				flex: 0 0 40%;
				max-width: 0 0 40%;
			}

			.text {
				//order: 1;
			}

			.desc {
				display: none;
			}
		}
	}

	.press {
		margin-top: 40px;
	}

	.contact-form {
		.mobile-hidden-field {
			display: none;
		}
	}
	.about-logo-wrapper {
		margin-top: 20px;
	}

	.about-logo {
		height: initial;

		&.about-logo-first-box {
			margin-bottom: 15px;
		}
	}
}

/*========================= Viewport width 575px and lower =========================*/

@media screen and (max-width: 575px) {
	html {
		font-size: 14px;
	}

	h2,
	.h2 {
		@include rem(font-size, 34px);
	}

	h3,
	.h3 {
		@include rem(font-size, 25px);

		&.small {
			@include rem(font-size, 25px);
		}
	}

	.block {
		@include rem(padding, 60px 0);
	}

	.teaser-hp {
		.teaser-content {
			.text {
				@include rem(padding, 40px 15px);
			}
		}
	}

	.teaser-alt {
		padding-left: 0px;
		padding-right: 0px;
	}

	.header {
		.logo-cities-wrap {
			max-width: 220px;
		}

		.h_0 {
			padding-left: 0px;
			padding-right: 0px;
		}
		.h_1 {
			padding-left: 0px;
			padding-right: 0px;
		}
	}

	.nav-btn {
		position: relative;
		top: -12px;
	}

	.services, .eshop {
		.item-inner {
			.text {
				@include rem(padding, 24px 0 24px 24px);
			}
		}
	}

	.eshop {
		.item-inner {
			.text {
				flex: 0 0 65%;
				max-width: 65%;
				@include rem(padding, 35px 35px 35px 35px);
			}

			.images {
				.svg-img {
					display: none;
				}

				.img {
					flex: 0 0 100%;
					max-width: 100%;
				}
			}

		}
	}

	.posts {
		.item-nl {
			.item-inner {
				@include rem(padding, 24px);
			}
		}
	}

	.mobile-version {
		display: inline;
	}

	.pc-version {
		display: none;
	}

	.case-studies {
		.item {
			.text {
				@include rem(padding, 24px);
			}
		}

		.foot {
			flex-wrap: wrap;
		}

		.slider-nav {
			margin-right: 20px;
		}
	}

	.persons-list {
		.person-box {
			justify-content: center;

			div {
				width: 260px;
			}
		}
	}

	.logo-foot {
		.frame {
			max-width: 200px;
		}
	}

	.post-list {
		.item-post {
			.item-inner {
				flex-wrap: wrap;
			}

			.img {
				flex: 0 0 100%;
				max-width: 100%;
				order: 0;

				img {
					width: 100%;
				}
			}

			.text {
				order: 1;
			}
		}
	}

	.post-info {
		ul {
			flex-wrap: wrap;

			li {
				flex: 0 0 100%;
			}
		}
	}

	.post-detail {
		.post-video {
			.play {
				@include rem(width, 64px);
				@include rem(height, 64px);

				&:after {
					border-width: 18px 0 18px 28px;
				}
			}
		}
	}

	.clients {
		.item {
			padding: 0 10px;
		}
	}

	.fix-nav {
		display: none;
	}

	#newsletter-form {
		.modal-box-wrap {
			padding: 0;
		}
	}
}
