/************************************************************************************
HEADER
*************************************************************************************/

.header {
	//position: absolute;
	position: relative;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 130;

	.h_0 {
		background: #000;

		.inner {
			@include rem(height, 48px);
		}
	}

	.h_1 {
		background: $black;

		.inner {
			@include rem(height, 172px);
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	.inner {
		@include rem(height, 100px);
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
}

.lng-nav {
	ul {
		display: flex;
		margin: 0 -2px;

		li {
			text-transform: uppercase;
			padding: 0 2px;
			margin: 1px 0;

			a {
				font-size: 14px;
				color: $gold;
				width: 32px;
				height: 32px;
				flex: 0 0 32px;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				text-decoration: none;

				&:hover {
					color: #fff;
				}
			}

			&.active {
				a {
					cursor: default;
					background: $gold;
					color: #000;
				}
			}
		}
	}
}

.contact-list-mini {
	@include rem(font-size, 16px);

	ul {
		display: flex;
		flex-wrap: wrap;
		@include rem(margin, 0 16px);

		li {
			@include rem(padding, 0 24px);
			margin: 0;
			color: $gold;

			a {
				color: $gold;
				display: flex;
				align-items: center;
				white-space: nowrap;
				text-decoration: none;

				&:hover {
					color: #fff;
				}

				.icon {
					@include rem(width, 17px);
					@include rem(flex, 0 0 17px);
					@include rem(height, 17px);
					//background: $gold;
					@include rem(margin-right, 10px);
				}
			}
		}
	}
}

.logo-cities-wrap {
	text-decoration: none;

	.cities {
		padding-top: 20px;
		margin-bottom: 0;

		a {
			text-decoration: none;
		}
	}
}

.teaser-hp {
	display: flex;
	margin-bottom: 70px;

	.teaser-img {
		width: 100%;
		margin-right: -100%;

		img {
			width: 100%;
		}
	}

	.teaser-content {
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		height: 100%;
		width: 100%;

		.text {
			@include rem(font-size, 24px);
			line-height: 1.5em;
			background: $black;
			color: $gold;
			@include rem(padding, 50px);
			@include rem(max-width, 810px);
			margin-bottom: -70px;

			h1 {
				color: $gold;
				@include rem(margin-bottom, 20px);
			}

			p {
				@include rem(margin-bottom, 30px);
			}

			.btn {
				color: $black;

				&:hover {
					background: #fff;
					border-color: #fff;
				}
			}
		}
	}

	.container,
	.row,
	.col-12 {
		display: flex;
		flex-grow: 1;
	}
}

.teaser-alt {
	background: $black;
	border-top: 1px solid #44484f;

	h1 {
		color: $gold;
		margin-bottom: 10px;
		margin-top: 20px;
	}

	.teaser-content {
		@include rem(padding, 25px 0);
	}

	.post-info {
		color: #fff;
		padding: 10px 0 0 0;
	}

	.teaser-perex {
		p {
			color: $gold;
			padding: 25px 0 25px 0;
		}
	}

	&.teaser-breadcrumbs-transparent {
		position: absolute;
		top: 0;
		width: 100%;
		background: rgba(33, 38, 46, 0.9);
	}
}

.breadcrumbs {
	color: #fff;

	a {
		color: #fff;
		text-decoration: none;

		&:hover {
			color: $gold;
		}
	}

	.current {
		color: $gold;
	}
}

.specialization-teaser-wrapper {
	position: relative;
}

.main-nav {
	vertical-align: top;
	text-align: left;

	ul {
		display: flex;
		align-items: center;
		@include rem(margin, 0 -12px);

		ul {
			display: none;
		}

		li {
			@include rem(padding, 0 12px);
			margin: 0;

			&:hover ul {
				display: block;
			}

			a {
				@include rem(font-size, 20px);
				font-weight: 500;
				color: #fff;
				display: block;
				@include rem(padding, 10px 16px);
				text-decoration: none;
				position: relative;
				@include transition(all, 0.2);

				&:after {
					content: "";
					background: #fff;
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 3px;
				}

				&:hover {
					color: $black;

					&:after {
						width: 100%;
						height: 100%;
						background: $gold;
						@include transition(all, 0.2);
					}
				}

				.txt {
					position: relative;
					z-index: 10;
				}
			}

			&.active {
				a {
					background: $gold;
					color: $black;

					&:after {
						background: $gold;
					}
				}

				ul {
					a {
						background: $black;
						color: $white;

						&:after {
							background: $white;
						}
					}
				}
			}

			ul {
				background: #2a313b;
				padding: 10px 0px;
				position: absolute;
				top: 100;
				margin-right: 8px;

				li {
					a {
						@include rem(font-size, 20px);
						font-weight: 500;
						color: #fff;
						display: block;
						@include rem(padding, 10px 16px);
						text-decoration: none;
						position: relative;
						@include transition(all, 0.2);
						min-width: 90px;

						&:after {
							content: "";
							background: #fff;
							position: absolute;
							left: 0;
							top: 0;
							width: 100%;
							height: 1px;
						}

						&:hover {
							color: $black;

							&:after {
								width: 100%;
								height: 100%;
								background: $gold;
								@include transition(all, 0.2);
							}
						}

						.txt {
							position: relative;
							z-index: 10;
						}
					}

					&.active {
						a {
							background: $gold;
							color: $black;

							&:after {
								background: $gold;
							}
						}
					}
				}
			}
		}
	}
}

.nav-btn {
	display: none;
	vertical-align: top;
	width: 30px;
	height: 30px;
	margin-left: 30px;
	text-align: center;
	position: relative;
	z-index: 2001;
	cursor: pointer;
	align-items: center;

	.lines {
		width: 30px;
		height: 4px;
		flex: 0 0 30px;
		display: block;
		position: relative;
		position: absolute;
		top: 50%;
		margin-top: -2px;
	}

	.line-1,
	.line-2,
	.line-3 {
		display: block;
		width: 100%;
		height: 2px;
		background: #fff;
		transition: 0.1s;
		position: absolute;
		left: 0;
		content: "";
		transform-origin: 0.28571rem center;
	}

	.line-1 {
		top: 0;
		transition: opacity 0.1s 0.1s ease;
	}

	.line-2 {
		top: 10px;
		transform-origin: 50% 50%;
		transition: top 0.1s 0.1s ease, -webkit-transform 0.1s ease;
		transition: top 0.1s 0.1s ease, transform 0.1s ease;
	}

	.line-3 {
		top: -10px;
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
		transition: top 0.1s 0.1s ease, -webkit-transform 0.1s ease;
		transition: top 0.1s 0.1s ease, transform 0.1s ease;
	}

	&.active {
		.line-1 {
			transition: opacity 0.1s 0s ease;
			background: transparent;
			opacity: 0;
		}

		.line-2 {
			transform: rotate3d(0, 0, 1, 45deg);
			transition: top 0.1s ease, transform 0.1s 0.1s ease;
			top: 0;
		}

		.line-3 {
			transform: rotate3d(0, 0, 1, -45deg);
			transition: top 0.1s ease, transform 0.1s 0.1s ease;
			top: 0;
		}

		&:hover {
			.line-1,
			.line-2,
			.line-3 {
				background: #fff;
			}
		}
	}
}

.mobile-nav {
	background: #21262e;
	width: 100%;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 90;
	overflow-y: auto;
	max-height: 100%;
	margin-top: -10px;
	@include rem(padding, 200px 0 30px 0);
	@include transition(all, 0.3);
	@include translate(0, -100%);

	&.active {
		@include translate(0, 0);
	}

	ul {
		margin: 0;
		padding: 0;

		ul {
			border-top: 1px solid rgba(255, 255, 255, 0.1);
		}

		li {
			color: #fff;
			margin: 0;
			padding: 0;
			background: none;
			border: none;
			border-bottom: 1px solid rgba(255, 255, 255, 0.1);

			&.active {
				a {
					color: #ffa70e;
				}
			}

			&:last-child {
				border: none;
			}

			a {
				@include rem(font-size, 17px);
				font-weight: 400;
				display: block;
				width: 100%;
				padding: 14px 0;
				color: #fff;
				border: none;
				position: relative;
				text-decoration: none;
			}
		}
	}
}

.mobile-nav-overlay {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 80;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.9);
	display: none;
}

.mobile-nav-wrap {
	display: none;
}

body.mobile-nav-opened {
	overflow: hidden;

	.mobile-nav {
		@include translate(0, 0);
		margin-top: 0;
	}

	.nav-btn {
		.line-1 {
			transition: opacity 0.1s 0s ease;
			background: transparent;
			opacity: 0;
		}

		.line-2 {
			transform: rotate3d(0, 0, 1, 45deg);
			transition: top 0.1s ease, transform 0.1s 0.1s ease;
			top: 0;
		}

		.line-3 {
			transform: rotate3d(0, 0, 1, -45deg);
			transition: top 0.1s ease, transform 0.1s 0.1s ease;
			top: 0;
		}

		&:hover {
			.line-1,
			.line-2,
			.line-3 {
				background: #fff;
			}
		}
	}

	.mobile-nav-overlay {
		display: block;
		background: rgba(0, 0, 0, 0.9);
	}
}
