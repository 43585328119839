.tabs {
	position: relative;
}
.tabs .tab-nav ul {
	margin: 0 0 0 -1px;
	padding: 0;
}
.tabs .tab-nav li {
	float: left;
	list-style-type: none;
	padding: 0;
	background: none;
	margin: 0 0 2em 1px;
}
.tabs .tab-nav li a {
	display: block;
	text-decoration: none;
	outline: 0;
	padding: 15px 4em 15px 0;
	margin-right: 1em;
	color: $textColor;
	position: relative;
	transition: color 0.15s ease-in;

	&:after {
		content: "";
		width: 100%;
		height: 4px;
		background: $mainColor;
		position: absolute;
		left: 0;
		bottom: 0;
		opacity: 0;
		transition: opacity 0.15s ease-in;
	}
}
.tabs .tab-nav li a:hover,
.tabs .tab-nav li a:active {
	color: $green;

	&:after {
		opacity: 1;
	}
}
.tabs .tab-nav li a.selected {
	color: $textColor;

	&:after {
		opacity: 1;
	}
}
.tabs .tab-content .tab {
	float: left;
	width: 100%;
	display: none;
	padding-bottom: 40px;
}
.tabs .tab-content .tab.selected {
	position: static !important;
	display: block;
}
